<template>
  <ProductCellFormList
    type="projects"
    :headers="searchItems"
    :disabled-edit-mode="disabledEditMode"
    :item="item"
    :view="''"
    @saveFileProcess="saveFileProcess"
    v-on="$listeners" />
</template>

<script>
import { mapGetters } from 'vuex';

import ProductCellFormList from '@/components/Product/ProductCell/ProductCellProjectsFormList';

import { sortAsideHeadersLast } from '@/utils/productHeaders';

export default {
  name: 'RowDataList',
  components: {
    ProductCellFormList,
  },
  props: {
    disabledEditMode: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      default: null,
    },
    searchText: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      tableHeaders: 'ScheduleViews/tableHeaders',
    }),
    filteredHeaders() {
      return this.tableHeaders.filter(header => header.value !== 'order');
    },
    renderHeaders() {
      return this.filteredHeaders.toSorted(sortAsideHeadersLast);
    },
    searchItems() {
      const { renderHeaders, searchText } = this;
      return renderHeaders.filter(item => {
        if (!searchText) return true;
        return item['value'].toLowerCase().includes(searchText.toLowerCase());
      });
    },
  },
  methods: {
    saveFileProcess(e = false) {
      this.$emit('saveFileProcess', e);
    },
  },
};
</script>
